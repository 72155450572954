<template>
  <div id="home">
    <el-container>
      <el-main>
        <div class="mainContent">
          <div class="mainContent__centerwarp">
            <div class="tip">
              《网络违法犯罪举报网站》不具备现场、紧急报警的受理功能，如您的情况紧急，请立即拨打报警电话“110”。
            </div>
            <div class="mainContent__centerwarp__title">
              <!-- <el-image
                style="width: 592px; height: 223px"
                :src="require('@/assets/portalImage/title1.png')"
                fit="contain"
              ></el-image> -->
            </div>
            <div class="mainContent__centerwarp__btnList">
              <div class="btnText" @click="handleLogin">我要举报</div>
            </div>
          </div>
        </div>
      </el-main>
      <el-footer>
        <div class="footer__container">
          <div class="leftFont">
            <p class="white">版权所有：公安部网络安全保卫局</p>
            <p class="white">
              <el-image
                :src="require('@/assets/web4.png')"
                fit="contain"
              ></el-image>
              <a
                class="jgawb"
                style="color: #749ae3 !important"
                href="https://beian.mps.gov.cn/#/query/webSearch?code=11010102000001"
                target="_blank"
              >
                京公网安备 11010102000001号</a
              >
              <!-- https://beian.mps.gov.cn/registerSystemInfo?recordcode=11010102000001 -->
              <a
                class="jicpb"
                style="color: #749ae3 !important"
                href="https://beian.miit.gov.cn/#/Integrated/index"
                target="_blank"
                >京ICP备05070602号&nbsp;&nbsp;&nbsp;</a
              >
            </p>
            <p class="white">
              建议您使用IE9及其以上版本，Edge、Chrome、FireFox和360等主流浏览器浏览本网站
            </p>
          </div>
          <div class="rightPicGroup">
            <div class="leftPicture">
              <div class="pic1">
                <a @click="handleClickZhaoCuo()">
                  <el-image
                    style="
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      margin-left: -55px;
                      margin-top: -27px;
                    "
                    :src="require('@/assets/web1.png')"
                    fit="contain"
                  ></el-image>
                </a>
              </div>
              <div class="pic2">
                <a @click="handleClickDz()"
                  ><el-image
                    style="
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      margin-left: -28px;
                      margin-top: -34px;
                    "
                    :src="require('@/assets/web2.png')"
                    fit="contain"
                  ></el-image
                ></a>
              </div>
            </div>
            <div class="rightPicture">
              <el-image
                style="float: left"
                :src="require('@/assets/web3.png')"
                fit="contain"
              >
              </el-image>
            </div>
          </div>
        </div>
      </el-footer>
      <!-- 上线升级公告 -->
      <el-dialog
        v-model="upgradeDialogVisible"
        width="600px"
        destroy-on-close
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        class="dialog"
      >
        <template #header>
          <div class="header padding-10">“网络违法犯罪举报网站”通知公告</div>
        </template>
        <div class="gonggao">
          <!-- <p>尊敬的网站用户：</p> -->
          <p class="intent-1 line-height-10">
            本网站为互联网违法有害信息举报平台，仅接收有关互联网违法有害信息的举报。涉及刑事案件、治安案件、行政案件的，请到县级公安机关及有关部门报案；涉及民事纠纷的，请通过司法途径解决。感谢您的理解与支持。
          </p>
          <!-- <p class="intent-1">特此公告。</p>
          <p class="align-right">2024年1月24日</p> -->
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button
              size="small"
              type="primary"
              class="btn"
              @click="upgradeDialogVisible = false"
              >确认
            </el-button>
          </span>
        </template>
      </el-dialog>
    </el-container>
  </div>
</template>

<script>
// import { readUserAgent } from "@/utils/userAgent.js";
export default {
  name: "PortalHome",
  data() {
    return {
      upgradeDialogVisible: true,
    };
  },
  methods: {
    handleClickZhaoCuo() {
      const el = document.createElement("a");
      document.body.appendChild(el);
      const url = `https://zfwzgl.www.gov.cn/exposure/jiucuo.html?site_code=bm09000011&url=${encodeURIComponent(
        window.location.href
      )}`;
      el.href = url; // url 是你得到的连接
      el.target = "_blank"; // 指定在新窗口打开
      el.click();
      document.body.removeChild(el);
    },
    handleClickDz() {
      const el = document.createElement("a");
      document.body.appendChild(el);
      const url =
        "https://bszs.conac.cn/sitename?method=show&id=223521F9C45E0E5EE053022819ACAB07";
      el.href = url; // url 是你得到的连接
      el.target = "_blank"; // 指定在新窗口打开
      el.click();
      document.body.removeChild(el);
    },
    handleClickQuery() {
      this.$router.push("/query/webSearch");
    },
    handleLogin() {
      // let data = readUserAgent();
      // if (data.device !== "windows") {
      // this.$confirm(
      //   "本网站建议使用电脑端，支持Edge、Chrome、FireFox和360等主流浏览器浏览本网站！",
      //   "提示",
      //   {
      //     confirmButtonText: "仍要举报",
      //     cancelButtonText: "",
      //     type: "warning",
      //   }
      // )
      //   .then(() => {
      //     window.location.href = "/cyber_sso/gov/isLogin";
      //     // window.location.href = "/login";
      //   })
      //   .catch(() => {});
      // return false;
      // }
      window.location.href = "/cyber_sso/gov/isLogin";
      // window.location.href = "/login";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/breakpoint.scss";
#home {
  height: 100%;
}
.el-main {
  padding: 0px !important;
  .mainContent {
    width: 100%;
    background: url(~@/assets/portalImage/bg1.jpg) no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
    &__centerwarp {
      // position: relative;
      height: 100%;
      // display: flex;
      // justify-content: center;
      // flex-direction: column;
      .tip {
        // position: absolute;
        padding-top: 20px;
        // left: 50%;
        // transform: translate(-50%, 0);
        white-space: wrap;
        // left: 50%;
        // margin-left: -630px;
        // margin-top: 17px;
        font-family: MicrosoftYaHei-Bold;
        font-size: 20px;
        color: #ffffff;
        letter-spacing: 5.2px;
        text-align: center;
        font-weight: 700;
        width: calc(100vw);
        @media screen and (max-width: $screen-xs) {
          font-size: 14px;
        }
        @media screen and (min-width: $screen-sm) {
          font-size: 20px;
        }
        @media screen and (min-width: $screen-md) {
          font-size: 20px;
        }
        @media screen and (min-width: $screen-lg) {
          font-size: 20px;
        }
      }
      &__title {
        // position: absolute;
        // padding-top: 22%;
        // transform: translate(-52%, 0);
        // left: 50%;
        // margin-left: -265px;
        // top: 40%;
        // margin-top: -102px;
        max-width: 590px;
        height: 45%;
        max-height: 223px;
        margin: 9% auto;
        background: url(~@/assets/portalImage/title1.png) 20px center no-repeat;
        background-size: contain;
        @media screen and (max-width: $screen-xs) {
          margin: 0px auto;
          background: url(~@/assets/portalImage/title1.png) 10px center
            no-repeat;
          background-size: contain;
        }
        @media screen and (min-width: $screen-xs) {
          margin: 20% auto;
        }
        @media screen and (min-width: $screen-sm) {
          margin: 20% auto;
        }
        @media screen and (min-width: $screen-md) {
          margin: 9% auto;
        }
        @media screen and (min-width: $screen-lg) {
          margin: 6.3% auto;
        }
      }
      &__btnList {
        @media screen and (max-width: $screen-xs) {
        }
        @media screen and (min-width: $screen-sm) {
        }
        @media screen and (min-width: $screen-md) {
        }
        @media screen and (min-width: $screen-lg) {
        }
        .btnText {
          z-index: 999;
          width: 326px;
          height: 100px;
          line-height: 100px;
          background-color: #ffffff;
          // position: absolute;
          cursor: pointer;
          margin: 0 auto;
          // top: 50%;
          // margin-top: 35px;
          // left: 50%;
          // margin-left: -116px;
          font-family: MicrosoftYaHei-Bold;
          font-size: 52px;
          color: #183288;
          letter-spacing: 3.25px;
          text-align: center;
          font-weight: 700;
          @media screen and (max-width: $screen-xs) {
            width: 150px;
            height: 50px;
            line-height: 50px;
            font-size: 22px;
          }
          @media screen and (min-width: $screen-sm) {
            width: 250px;
            height: 100px;
            line-height: 100px;
            font-size: 32px;
          }
          @media screen and (min-width: $screen-md) {
            width: 326px;
            height: 100px;
            line-height: 100px;
            font-size: 52px;
          }
          @media screen and (min-width: $screen-lg) {
            width: 326px;
            height: 100px;
            line-height: 100px;
            font-size: 52px;
          }
        }
        .btn:first-child {
          margin-right: 15px;
        }
      }
      &__manualList {
        position: absolute;
        left: 50%;
        margin-left: -525px;
        margin-top: 550px;
        width: 1050px;
        height: 21px;
        .manualBtn {
          text-decoration: underline;
          float: left;
          width: 210px;
          height: 21px;
          color: white;
          font-family: MicrosoftYaHei;
          font-size: 16px;
          color: #ffffff;
          letter-spacing: 1px;
          text-align: center;
          line-height: 21px;
          font-weight: 400;
        }
      }
    }
  }
}
body {
  overflow-x: hidden;
  min-height: 100vh;
  margin: 0px;
}
.main {
  min-height: calc(100vh - 347px);
}
.el-footer {
  width: 100%;
  padding: 5px;
  bottom: 0px;
  clear: both;
  height: 159px !important;
  .link {
    .center__wrapper {
      max-width: 1247px;
      margin: 0 auto;
    }
    a {
      color: white;
      text-decoration: none;
      width: 1247px;
      font-family: MicrosoftYaHei;
      font-size: 12px;
      letter-spacing: 2.28px;
      text-align: center;
      line-height: 39px;
      font-weight: 400;
    }
  }
  .footer__container {
    // position: relative;
    max-width: 1247px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .info {
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #2c2c2c;
      letter-spacing: 3.38px;
      text-align: center;
      line-height: 50px;
      font-weight: 400;
    }
    .w1100 {
      position: absolute;
      left: 50%;
      margin-left: -300px;
      .lianj {
        height: 40px;
        line-height: 25px;
        border-bottom: 1px solid rgb(27, 65, 163);
        li {
          list-style-type: none;
          float: left;
          margin-right: 26px;
          font-size: 16px;
          b {
            color: #2c2c2c;
            font-size: 18px;
          }
          a {
            color: #2c2c2c;
            text-decoration: none;
          }
        }
      }
    }
    .leftFont {
      // position: absolute;
      text-align: left;
      .jgawb {
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #2c2c2c;
        letter-spacing: 0;
        line-height: 20px;
        font-weight: 400;
        text-decoration: none;
      }
      .jicpb {
        margin-left: 10px;
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #749ae3;
        letter-spacing: 0;
        line-height: 20px;
        font-weight: 400;
        text-decoration: none;
      }
      p {
        font-family: "微软雅黑";
        font-size: 12px;
        letter-spacing: 0;
        font-weight: 400;
        color: #4f75dc;
        line-height: 19px;
      }
      .white {
        color: #2c2c2c;
        display: flex;
        align-items: center;
      }
    }
    .rightPicGroup {
      display: flex;
    }
    .leftPicture {
      // position: absolute;
      // right: 0%;
      // margin-right: 84px;
      .pic1 {
        cursor: pointer;
        position: relative;
        float: left;
        width: 123px;
        height: 89px;
      }
      .pic2 {
        cursor: pointer;
        margin-right: 17px;
        position: relative;
        float: left;
        width: 89px;
        height: 89px;
      }
    }
    .rightPicture {
      // position: absolute;
      // right: 0%;
      // margin-left: 221px;
    }
  }
}

.gonggao {
  text-align: left;
  // display: inline-block;
  // width: 40%;
  span {
    display: inline-block;
    &.intent-1 {
      text-indent: 0.8cm;
    }
  }
  p {
    color: #000000d9;
    font-size: 15px;
    // margin: 0;
    &.line-height-10 {
      line-height: 22px;
    }
    &.intent-1 {
      text-indent: 0.8cm;
    }
    &.align-right {
      text-align: right;
    }
  }
}
</style>
<style lang="scss">
.footer__container {
  .el-divider--horizontal {
    margin: 0px 0px;
  }
}
.el-container {
  height: 100%;
  .el-main {
    overflow: inherit;
  }
}

.el-overlay,
.el-overlay-dialog {
  // width: calc(100vw);
}
.dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
  font-size: 15px;
  .el-dialog__header {
    text-align: center;
    color: #000;
    font-weight: bold;
    font-size: 18px;
    padding: 8px;
    margin: 0;
    border-bottom: 1px solid #f0f0f0;
    .header.padding-10 {
      padding: 8px;
    }
  }
  .el-dialog__body {
    padding: 10px 20px;
    .countdown {
      text-align: left;
      color: #000000d9;
      font-size: 15px;
    }
  }

  span {
    &.time {
      color: white;
    }
  }
  .btn {
    font-size: 15px;
    // padding: 8px;
    // margin: 0;
  }
}
</style>
